import request from '@/utils/request.js';
import { reqMethodEnum } from '@/enumerators/http.js'
const { GET, POST } = reqMethodEnum;

// 学校列表
export function GetSchoolTypeList(data) {
  return request({
    url: '/backend/web/school-type/index',
    method: GET,
    params: data
  });
}

// 学校类型新增
export function PostSchoolTypeCreate(data) {
  return request({
    url: '/backend/web/school-type/create',
    method: POST,
    data
  });
}

// // 学校类型删除
export function PostSchoolTypDelete(data) {
  return request({
    url: '/backend/web/school-type/delete',
    method: POST,
    data
  });
}

// ------------------------------------------

// 管理端-菜单配置 列表
export function GetSchoolTypeMenus(data) {
  return request({
    url: '/backend/web/school-type/menus',
    method: GET,
    params:data
  });
}

// 管理端-菜单配置 删除
export function PostSchoolTypeDelete(data) {
  return request({
    url: '/backend/web/school-type/menu-delete',
    method: POST,
    data
  });
}

// 管理端-菜单配置 编辑
export function PostSchoolTypeMenuUpdate(data) {
  return request({
    url: 'backend/web/school-type/menu-update',
    method: POST,
    data
  });
}

// 管理端-菜单配置 编辑详情
export function GetSchoolTypeMenuDetail(data) {
  return request({
    url: 'backend/web/school-type/menu-detail',
    method: GET,
    params:data
  });
}

// 管理端-菜单配置 更改状态
export function PostSchoolTypeStatus(data) {
  return request({
    url: '/backend/web/school-type/menu-update-status',
    method: POST,
    data
  });
}

// 管理端-菜单配置 添加菜单
export function PostSchoolTypeMenuCreate(data) {
  return request({
    url: '/backend/web/school-type/menu-create',
    method: POST,
    data
  });
}